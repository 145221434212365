import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'
import Video from '../../panels/Video'
import Link from '../../Link'

const HeaderCopyHeading = styled.div`  
  object-fit: contain;
  font-weight: 500;
  font-family: Objectivity;
  font-size: 50px;
  color: white;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -1.46px;
  text-align: center;
  font-style: normal;

  @media (max-width: 768px) {
    font-size: 52px;
  }

  @media (max-width: 768px) {
    margin: 0;
    font-size: 42px;
    line-height: 1.39;
    letter-spacing: 1;
    width: 90%;
  }
`
const HeaderCopySubHeader = styled.div`
  font-size: 25px;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  text-align: center;
  padding-top: 10px;
  font-weight: 400;
  font-family: Objectivity;
  font-style: normal;
  color: white;

  @media (max-width : 1025px) {
    font-size: 18px;
    letter-spacing: -0.16px;
  }
  @media (max-width: 768px) {
    opacity: 1;
    padding-top: 0;
    font-size: 14px;
    letter-spacing: -0.12px;
    text-align: center !important;
  }
  & p {
	  margin: 16px;
    margin-bottom: 0px;
	}
`
const SectionContainerTop = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  @media (max-width: 768px) {
    height: calc(75vh - 48px);
  }
  @media (min-width: 769px) {
    height: calc(100vh - 126px);
  }
`
const CopyWidthContainer = styled.div`
  text-align: center;
  @media (min-width: 769px) {
    max-width: 1000px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
  }
`
const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  border: solid 1px white;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 49px;
  letter-spacing: 0.45px;
  text-align: center;
  color: white;
  background: transparent;
  height: 49px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  width: 300px;
  @media (max-width: 1024px) {
    font-size: 10px;
    height: 42px;
    line-height: 42px;
  }
  transition: all 0.2s;
  &:hover {
    background-color: rgba(var(--dark-blue-grey), 0.1);
    color: white;
    border-color: transparent
  }
  &:active {
    background-color: transparent;
    color: white;
    border-color: white
  }
`
const TitleImage = styled(GatsbyImage)`
  width: 1000px;
  @media (max-width: 768px) {
    width: 350px;
    height: 250px;
    object-fit: contain;
  }
`

const StaticHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;' : '')}
  }
  object-fit: cover;
`

const HeaderVideo = styled(Video)`
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`
const SectionCopy = styled.div`
  position: absolute;
  ${(props) => (props.backgroundColor ? `background: ${props.backgroundColor}` : '')}
  ${(props) => props.full && 'width: 100%;'};
  left: 0;
  right: 0;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(var(--${(props) => props.color}));
  top: 0;
  justify-content: center;
  justify-content: ${(props) => (props.to?.includes('personalisation') ? `start;` : 'center;')};
  padding-top: ${(props) => (props.TextPosition === 'start' ? `2%;` : '0;')};
  @media (min-width: 769px) { 
    ${({ trunk }) => trunk && `align-items: flex-end; text-align: center; padding-right: 10%;`}
    ${({ school }) => school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
    ${(props) => (props.buttonPos && `justify-content: ${props.buttonPos}; padding-bottom: 10vh`)};
  }
  @media (max-width: 768px) {
    top: 0%;
    width: 100%;
    ${({ noMin }) => noMin && 'height : 100%;'}
    text-align: left;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
    props.center ? 'text-align: center; align-items: center;' : ''}
    ${({ trunk }) => trunk && `align-items: center;text-align: center; `}
    ${(props) => (props.mobileAbsolute ? 'position: absolute; top: 22px; padding-top: 8px;' : '')}
    justify-content: ${(props) => (props.TextPosition ? `${props.TextPosition};` : 'center;')};
    padding-top: ${(props) => (props.TextPosition === 'start' ? `5%;` : '0;')};
    ${(props) => (props.buttonPos && `justify-content: ${props.buttonPos}; padding-bottom: 20vh`)};
  }
  @media (max-width: 768px) {
    height: ${(props) => props.BorderColor === '#FFAC42' ? `80%;` : ''};
  }
  @media (max-width: 320px) {
    ${({ noMin }) => !noMin && 'height: 90%'}

  }
`
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

function extractFilename(path) {
  // Split the path by '/' (or '\' for Windows)
  const parts = path.split('/'); // Use '\\' for Windows paths

  // Get the last part (which should be the filename)
  const filename = parts[parts.length - 1];

  return filename;
}

const BackgroundContainer = styled.div`
  position: static;
  display: flex;
`
const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 24px;
  align-items: center;
  position: fixed;
  z-index: 999;
`
const Menu = styled.div`
  position: absolute;
  left: 0;
  margin-left: 40px;

  & a {
    text-decoration: none;
    color: #fff;
  }
`
const CartContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  margin-right: 40px;
`
const Cart = styled.div`
  margin: 0 12px;
`
const Search = styled.div`
  margin: 0 12px;
`

const IconOutline = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #818285;
  border-radius: 50%;
`
const Header = styled.div`
`
const SubHeader = styled.div`
`
const Button = styled.button`
  font-family: Objectivity;
  border-radius: 50px;
  border: solid 1px white;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  background: transparent;
  height: 60px;
  width: 275px;
  padding: 0 48px;
  outline: none;
  cursor: pointer;
  text-align: center;
  color: white;
  margin: 24px 0;
  @media (max-width: 768px) {
    font-size: 10px;
    height: 45px;
    width: 200px;
  }

  transition: all 0.2s;

  &:hover {
    background-color: grey;
    color: white;
    border-color: transparent;
  }
  &:active {
    background-color: transparent;
    color: grey;
    border-color: transparent;
  }
  outline-color: transparent;
`

const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  ${(props) => (props.background ? 'background: black;' : '')}

  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) => (props.fullLength ? 'width: 100%;height: calc(100vh - 110px);' : '')}
  }

  img {
    opacity: 1 !important; /* Override the default opacity */
  }
`

const FullBanner = ({ image, header, subHeader, button }) => {
  return (
    <Link to={button?.link} noLink={button?.link === null} >
      <SectionContainerTop>
        {
          image?.isStatic ? (
            <StaticHeaderImage src={image?.url}
            />
          ) : (
            <>
              {image && (
                image?.Desktop?.mime?.includes('video') || image?.Desktop?.mime?.includes('gif') ? (
                  <HeaderVideo
                    loading='eager'
                    alt={image?.Desktop?.alternativeText || image?.Desktop?.name}
                    src={image?.Desktop?.file?.publicURL}
                  />
                ) : (
                  <HeaderImage
                    loading="lazy"
                    image={withArtDirection(getImage(image?.Desktop?.file?.childImageSharp?.gatsbyImageData), [
                      {
                        media: '(max-width: 768px)',
                        image: getImage(image?.Mobile?.file?.childImageSharp?.gatsbyImageDataMobile),
                      },
                    ])}
                  />
                )
              )}
            </>
          )
        }
        <SectionCopy>
          <CopyWidthContainer>
            <HeaderCopyHeading>
              {header}
            </HeaderCopyHeading>
            {subHeader && (
              <HeaderCopySubHeader dangerouslySetInnerHTML={{ __html: subHeader }} />
            )}
            {button && (
              <Button>
                {button.buttonText}
              </Button>
            )}
          </CopyWidthContainer>
        </SectionCopy>
      </SectionContainerTop>
    </Link>
  )
}

export default FullBanner
